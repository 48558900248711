import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/scrm',
    name: 'SCRM',
    component: () => import('@/views/enterpriseScrm')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/maintenance')
  }
]

const router = new VueRouter({
  mode: "history",
  // base:'/',
  routes
})

export default router
